:root {
  /* font weight */
  --default: 500;
  --semi: 600;
  --bold: 700;

  /* sides */
  --sides: 16px;

  /* spacers */
  --spacer-8: 8px;
  --spacer-12: 12px;
  --spacer-16: 16px;
  --spacer-24: 24px;
  --spacer-28: 28px;
  --spacer-32: 32px;
  --spacer-40: 40px;
  --spacer-48: 48px;

  /* colours */
  --soft-green: #f4fcef;
  --soft-orange: #fff9f1;
  --soft-grey: #fafafa;
  --sage: #a1e77b;
  --orange-500: #ffc9b5;
  --lavender-300: #8b4293;
  --black: #000;
  --white: #fff;

  /* effects */
  --link-transition: 150ms ease;

  /* elements */
  --nav-height: 54px;
}

@media screen and (min-width: 1000px) {
  :root {
    --sides: 32px;
    --nav-height: 77px;
  }
}
