#navigation {
  transition: background-color 250ms ease-in-out, transform 250ms ease;
}

.navigation--slide-up {
  transform: translateY(-100%);
}

.navigation--open {
  bottom: 0;
  background: rgba(255, 255, 255, 0.9);
  overflow-y: scroll;
}

.navigation--open .navigation__inner {
  border-bottom: unset;
  position: sticky;
  top: 0;
}

.navigation--open .navigation__overlay-nav-list {
  transform: translateX(0);
  height: auto;
  margin: calc(var(--spacer-48)) auto 0;
  opacity: 1;
  padding-bottom: 50px;
}

@media screen and (min-width: 1000px) {
  .navigation--open .navigation__overlay-nav-list {
    margin: calc(var(--spacer-48) * 2) auto 0;
    padding-bottom: 100px;
  }
}
