@font-face {
  font-family: "Work Sans";
  font-weight: 500;
  font-style: normal;
  font-display: swap;
  src: local("Work Sans Medium"), local("WorkSans-Medium"),
    url("../fonts/work_sans/WorkSans-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Work Sans";
  font-weight: 500;
  font-style: italic;
  font-display: swap;
  src: local("Work Sans Medium Italic"), local("WorkSans-MediumItalic"),
    url("../fonts/work_sans/WorkSans-MediumItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Work Sans";
  font-weight: 600;
  font-style: normal;
  font-display: swap;
  src: local("Work Sans SemiBold"), local("WorkSans-SemiBold"),
    url("../fonts/work_sans/WorkSans-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Work Sans";
  font-weight: 600;
  font-style: italic;
  font-display: swap;
  src: local("Work Sans SemiBold Italic"), local("WorkSans-SemiBoldItalic"),
    url("../fonts/work_sans/WorkSans-SemiBoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Work Sans";
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  src: local("Work Sans Bold"), local("WorkSans-Bold"),
    url("../fonts/work_sans/WorkSans-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Work Sans";
  font-weight: 700;
  font-style: italic;
  font-display: swap;
  src: local("Work Sans Bold Italic"), local("WorkSans-BoldItalic"),
    url("../fonts/work_sans/WorkSans-BoldItalic.ttf") format("truetype");
}

:root {
  --font: "Work Sans";
}
