.block-maintext h2 {
  @apply text-x7 lg:text-x10 font-semi mt-[1em] md:mt-[1.5em];
}
.block-maintext h3 {
  @apply text-x6 lg:text-x8 font-bold mt-[1em] md:mt-[1.5em];
}
.block-maintext h4 {
  @apply text-x6 lg:text-x7 font-semi md:font-bold md:mt-[0.5em];
}

.block-maintext ul {
  @apply list-disc ml-[1.2em];
}

.block-maintext ol {
  @apply list-decimal ml-[1.2em];
}

.block-maintext a {
  @apply underline underline-offset-2 transition;
}

.block-maintext a:hover {
  @apply text-orange-500;
}

.block-maintext u {
  @apply underline underline-offset-2 decoration-orange-500;
}

.block-maintext blockquote {
  @apply border border-black/10 py-4 px-5 md:p-8 bg-black/5 rounded-xl flex flex-col gap-6;
}

.block-maintext hr {
  @apply border-black/15;
}

.block-maintext blockquote > :first-child {
  @apply mt-0;
}
