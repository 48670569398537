.hero-h1 a {
  text-decoration: underline;
  text-decoration-color: rgba(0, 0, 0, 0.25);
  text-underline-offset: 0.1em;
}

.hero-h1 a:hover {
  text-decoration-color: rgba(255, 129, 83, 0.25);
  color: rgb(255, 129, 83);
}
