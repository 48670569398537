/* VIDEO */

.block-video * {
  color: inherit;
}

.block-video figcaption {
  font-size: 12px;
  color: black;
}

.block-video figure {
  width: 100%;
  height: 0;
  padding-top: 56.25%;
  position: relative;
}

.block-video iframe {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
}

/* LIST */

.block-list ul {
  list-style-type: disc;
  margin-left: 1em;
}

.block-list ol {
  list-style-type: decimal;
  margin-left: 1em;
}
