.global__layout-align {
  grid-column: 2;
}

.tutorial-details[open] summary {
  @apply bg-sage-100;
}

.tutorial-details[open] summary:hover {
  @apply bg-sage-200;
}

.tutorial-details[open] summary:active {
  @apply bg-sage-300;
}

.tutorial-details[open] .tutorial-details-arrow {
  @apply rotate-180;
}

.tutorial-details .block-maintext > :first-child,
.tutorial .block-maintext > :first-child {
  @apply mt-0;
}

details summary::-webkit-details-marker {
  display: none;
}
